import React from 'react'
import styled from 'styled-components'
import { Colors, MediaQuery } from 'styles'

interface SegmentControlProps {
  items: string[]
  active?: string
  onChange: (item: string) => void
}

export const SegmentControl: React.FC<SegmentControlProps> = ({
  items,
  active,
  onChange
}) => {
  return (
    <Control>
      {items.map((item) => {
        return (
          <Item
            onClick={() => onChange(item)}
            active={active === item}
            key={item}
          >
            {item}
          </Item>
        )
      })}
    </Control>
  )
}

const Control = styled.div`
  display: flex;
  flex-direction: row;
  /* border-radius: 5px !important; */
  background: ${Colors.blue};
  font-size: 0.9rem;
`

const Item = styled.div<{ active?: boolean }>`
  cursor: pointer;
  padding: 0.8rem 0.8rem;
  border-right: solid 1px #fff;
  :last-child {
    border-right: none;
  }
  background-color: ${(p) => (p.active ? `${Colors.darkBlue}` : 'inherit')};
  color: ${Colors.white};

  @media ${MediaQuery.Medium} {
    display: block;
    padding: 1rem 1.5rem;
  }
`
