import axios from 'axios'
import { MySchoolFiles } from '@cometloop/myschoolfiles-openapi'
import store from '../store'
import Cookies from 'universal-cookie'

// let isRefreshingToken = false

const axiosInstance = axios.create({
  timeout: 10 * 1000
})

axiosInstance.interceptors.request.use((request) => {
  const { mySchoolFilesAccessToken } = store.getState().identity
  if (mySchoolFilesAccessToken === '') return request
  request.headers['Authorization'] = `Bearer ${mySchoolFilesAccessToken}`
  return request
})

const cookies = new Cookies()

const mySchoolFilesOpenApiConfig = new MySchoolFiles.Configuration({
  basePath: process.env.REACT_APP_MY_SCHOOL_FILES_BASE_URL
})

export const shareClient = new MySchoolFiles.ShareApi(
  mySchoolFilesOpenApiConfig,
  undefined,
  axiosInstance
)

export const assignmentClient = new MySchoolFiles.AssignmentsApi(
  mySchoolFilesOpenApiConfig,
  undefined,
  axiosInstance
)
