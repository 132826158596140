import CalendarSummaryCard from 'components/CalendarSummaryCard'
import SchoolItemsSummaryCard from 'components/SchoolItemsSummaryCard'
import StudentCard from 'components/StudentCard'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Colors, Column, MediaQuery } from 'styles'
import { ReactComponent as MainImage } from 'themes/default/assets/Portfolio.svg'
import { assignmentClient, shareClient } from 'configuration'
import { useParams } from 'react-router'
import { useCookies } from 'react-cookie'
import {
  PORTFOLIOS_COOKIE_KEY,
  mergeCookieValue,
  ASSIGNMENTS_COOKIE_KEY
} from 'utils/cookies'
import PinInput from 'components/PinInput'
import { AssignmentChallenge } from '@cometloop/myschoolfiles-openapi/build/v1/myschoolfiles'
import imgStudent from 'assets/Vision.png'
import { RunMode } from 'types/runMode'
const Container = styled.div`
  margin: 50px 0;
  text-align: center;
`

const Center = styled(Column)`
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 100vh;
`

const Logo = styled.img`
  height: 150px;
  @media ${MediaQuery.Medium} {
    height: 250px;
  }
`

const Error = styled.p`
  color: red;
  font-size: 1.2rem;
`

const ActionTitle = styled.p`
  /* color: red; */
  font-size: 1.3rem;
  font-weight: 700;
  @media ${MediaQuery.Medium} {
    font-size: 1.6rem;
  }
`

interface PasscodeScreenProps {
  mode?: RunMode
}

const PasscodeScreen: React.FC<PasscodeScreenProps> = (props) => {
  const mode = props.mode || 'portfolios'

  const [disabled, setDisabled] = useState(false)
  const [invalidPasscode, setInvalidPasscode] = useState(false)
  const { shareUid, studentId } = useParams<any>()
  const [cookies, setCookie] = useCookies([
    PORTFOLIOS_COOKIE_KEY,
    ASSIGNMENTS_COOKIE_KEY
  ])

  const ref = React.useRef<PinInput>(null)

  const onComplete = async (value: string, index: number) => {
    try {
      setDisabled(true)
      setInvalidPasscode(false)

      if (mode === 'assignments') {
        const payload: AssignmentChallenge = {
          passcode: value,
          studentId: parseInt(studentId)
        }
        const { data } = await assignmentClient.assigmentChallenge(payload)
        setCookie(ASSIGNMENTS_COOKIE_KEY, data.token)
      } else {
        await shareClient.challenge({
          passcode: value,
          shareUid
        })
        setCookie(
          PORTFOLIOS_COOKIE_KEY,
          mergeCookieValue(shareUid, cookies[PORTFOLIOS_COOKIE_KEY] || [])
        )
      }
    } catch (e) {
      setInvalidPasscode(true)
      ref.current?.clear()
      setTimeout(() => {
        ref.current?.focus()
      })
    } finally {
      setDisabled(false)
    }
  }

  const img = mode === 'portfolios' ? '/app-icon.png' : imgStudent

  return (
    <Center>
      <Logo src={img} alt="My School Files" />
      <Container>
        <ActionTitle>Enter Passcode</ActionTitle>
        <PinInput
          ref={ref}
          focus={true}
          disabled={disabled}
          length={4}
          initialValue=""
          secret={false}
          type="numeric"
          inputMode="number"
          style={{ padding: 10 }}
          inputStyle={{
            borderWidth: 4,
            borderColor: Colors.pink,
            fontSize: 28,
            padding: 0,
            margin: 8
          }}
          inputFocusStyle={{ borderColor: Colors.pink }}
          onComplete={onComplete}
          autoSelect={true}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />
        {invalidPasscode && <Error>Incorrect passcode.</Error>}
      </Container>
    </Center>
  )
}

export default PasscodeScreen
