import React from 'react'
import styled from 'styled-components'
import loadingData from 'assets/lottie/skeleton.json'
import { boxShadow, Colors, MediaQuery } from 'styles'
import imgStudent from 'assets/Vision.png'
import {
  Category,
  Item
} from '@cometloop/myschoolfiles-openapi/build/v1/myschoolfiles'
import LinksCard from './LinksCard'
import DocumentsCard from './DocumentsCard'
import TextareaAutosize from 'react-textarea-autosize'
import { Element } from 'react-scroll'
import { itemHashId } from 'utils/links'
import { useInView } from 'react-intersection-observer'
import moment from 'moment'

const Card = styled.div`
  ${boxShadow};
  border-radius: 5px;
  padding: 60px;
  background: #fff;
  h1 {
    font-size: 1.2rem;
  }
  border-bottom: solid 1px #dcdcdc;
  /* &:last-child {
    border-bottom: none;
  } */
  @media ${MediaQuery.Small} {
    padding: 20px;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const NameWrap = styled.div`
  font-family: 'Poppins', sans-serif;
  /* font-weight: 700; */
  font-size: 1.8rem;
  width: 100%;
  @media ${MediaQuery.Small} {
    font-size: 1.6rem;
  }

  .item-date {
    padding: 7.5px 0 20px 0;
    font-size: 1.2rem;
    font-family: Arial, Helvetica, sans-serif;
    @media ${MediaQuery.Small} {
      font-size: 1rem;
    }
  }

  .description {
    /* width: calc(100% - 80px); */
    background: #fff;
    border: none;
    resize: none;
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    /* height: auto; */
    margin: 40px 0;
    /* padding: 40px; */
    font-size: 1.5rem;
    font-weight: normal;
    color: #555;
    white-space: pre-line;
    @media ${MediaQuery.Small} {
      font-size: 1rem;
      margin: 20px 0;
    }
  }

  .description.empty {
    font-style: italic;
  }
`

interface Props {
  item: Item
  category: Category
}

const ItemCard: React.FC<Props> = ({ item, category }) => {
  const {
    name,
    id,
    description,
    documentsCount,
    tagCount,
    tags,
    urlCount,
    photosCount,
    photos,
    documents,
    itemDate,
    urls
  } = item

  return (
    <Element name={itemHashId(item, category)}>
      <Card>
        <Row>
          <NameWrap>
            <p>{name}</p>
            <p className="item-date">
              {moment(itemDate).format('MMM D, YYYY')}
            </p>
            {description && <p className="description">{description}</p>}
          </NameWrap>
        </Row>
        <div>
          <DocumentsCard docs={photos || []} />
          <LinksCard urls={urls} />
        </div>
      </Card>
    </Element>
  )
}

export default ItemCard
