import React from 'react'
import Lottie from 'react-lottie'
import styled from 'styled-components'
import loadingData from 'assets/lottie/skeleton.json'
import { useSelectPortfolio } from 'store/appStateSlice'
import { boxShadow, Colors, MediaQuery } from 'styles'

// import Avatar from './Avatar'
// import imgStudent from 'assets/Vision.png'

interface Props {
  count: number
  fontSize?: string
}

const DayCircle: React.FC<Props> = (props) => {
  const { count } = props

  return (
    <StyledCount {...props}>
      <p>{count}</p>
    </StyledCount>
  )
}

const circleSize = 40

const StyledCount = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${circleSize}px;
  height: ${circleSize}px;

  ${boxShadow};
  border-radius: 50%;
  background: ${Colors.white};
  p {
    color: ${Colors.blue};
    font-size: 1.25rem;
  }
`

export default DayCircle
