import React from 'react'
import Lottie from 'react-lottie'
import styled from 'styled-components'
import loadingData from 'assets/lottie/skeleton.json'
import { useSelectPortfolio } from 'store/appStateSlice'
import { boxShadow, Colors, MediaQuery } from 'styles'
import AgendaItemCount from './AgendaItemCount'
import CalendarImage from 'themes/default/assets/calendar.jpg'
import SectionTitle from 'themes/default/components/SectionTitle'
import { Element } from 'react-scroll'

// import Avatar from './Avatar'
// import imgStudent from 'assets/Vision.png'

const Card = styled.div`
  font-family: 'Source Sans Pro', sans-serif;
  display: flex;
  flex-direction: row;
  flex: 1;
  /* ${boxShadow}; */
  /* border-radius: 5px; */
  padding: 20px;
  background: #fff;
  @media ${MediaQuery.Small} {
    flex-direction: column-reverse;
  }
`

const AgendaItemWrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  /* height: 100%; */
  justify-content: space-evenly;
  margin: 0px 40px;
  height: 500px;
  @media ${MediaQuery.Small} {
    flex: none;
    margin: 0px auto;
    height: 400px;
  }
  @media ${MediaQuery.Medium} {
    flex: none;
    /* margin: 0px 0px; */
  }
`

const ImageWrap = styled.div`
  display: flex;
  flex: 1;
  /* height: 100%; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
  }

  /* margin: 40px 0; */
`

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const CalendarSummaryCard: React.FC = () => {
  const portfolio = useSelectPortfolio()

  if (!portfolio) {
    return (
      <div>
        <Lottie options={defaultOptions} height={200} width={'100%'} />
      </div>
    )
  }

  const { calendarStats } = portfolio

  return (
    <Element name="calendar">
      <div>
        <SectionTitle>Calendar</SectionTitle>

        <Card>
          <AgendaItemWrap>
            <AgendaItemCount
              color={Colors.blue}
              label="School"
              count={calendarStats.schoolDays}
            />
            <AgendaItemCount
              color={Colors.yellow}
              label="Co-op"
              count={calendarStats.coOps}
            />
            <AgendaItemCount
              color={Colors.pink}
              label="Field Trip"
              count={calendarStats.fieldTrips}
            />
            <AgendaItemCount
              color={Colors.green}
              label="Total"
              count={calendarStats.totalItems}
            />
          </AgendaItemWrap>
          <ImageWrap>
            <img src={CalendarImage} />
          </ImageWrap>
        </Card>
      </div>
    </Element>
  )
}

export default CalendarSummaryCard
