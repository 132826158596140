import React from 'react'
import styled from 'styled-components'
import AppIcon from './AppIcon'
import { FaHeart } from 'react-icons/fa'
import { Colors } from 'styles'

const FooterBottom = styled.div`
  display: flex;
  padding: 40px 0;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Copy = styled.div`
  /* color: #fff; */
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.95rem;
  /* margin: 15px 0; */
  padding: 10px 10px;
  text-transform: none;
  line-height: 1.2rem;
  /* max-width: 800px; */
`

const Copy2 = styled(Copy)``

const AppAdvertise: React.FC = () => {
  return (
    <FooterBottom>
      <AppIcon />
      {/* <p>My School Files</p> */}
      {/* <p>Download the app</p> */}
      <Copy>
        <p>Created with My School Files</p>
      </Copy>
      <Copy2>
        <a href="https://myschoolfiles.com/download">
          Not already a member?
          <br /> Get started today.
        </a>
      </Copy2>
    </FooterBottom>
  )
}

export default AppAdvertise
