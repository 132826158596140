import React, { useEffect } from 'react'
import { useSelectPortfolio } from 'store/appStateSlice'
import styled from 'styled-components'
import loadingData from 'assets/lottie/loading.json'
import Lottie from 'react-lottie'
import { Colors } from 'styles'
import Avatar from './Avatar'
import { categoryHashId, itemHashId } from 'utils/links'
import {
  getStudentFullName,
  gradeLevelLabel,
  schoolYearRange
} from 'utils/helpers'
import Scroll from 'react-scroll'
import CountSidemenu from './CountSidemenu'
import { useSideMenu } from 'hooks/useSidemenu'
import { uppercaseEachWord } from 'utils/strings'
import AppIcon from './AppIcon'
import AppAdvertise from './AppAdvertise'
import useMediaQuery from 'hooks/useMediaQuery'

const MenuContainer = styled.div`
  min-width: 320px;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  height: 100vh;
`

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const FooterBottom = styled.div`
  display: flex;
  padding: 40px 0;
  flex: 1;
  align-items: center;
  justify-content: center;
`

const LoadingWrap = styled.div`
  width: 300px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

const CategoryWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  justify-content: flex-start;
  /* margin: 20px 40px; */
  h1 {
    background-color: #efefef;
    padding: 20px;
    text-align: left;
    /* margin: 0 40px; */
    /* padding: 20px; */
  }
`

const CategoryItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 23px;
  border-bottom: solid 1px #dcdcdc;
  font-size: 1rem;
  color: #fff;
  /* color: ${Colors.darkBlue}; */
  background: ${Colors.blue};
  /* background: #efefef; */
  p.label {
    /* text-decoration: underline; */
  }
`

const ItemLink = styled.div`
  display: flex;
  text-decoration: underline;
  justify-content: space-between;
  align-items: center;
  padding: 10px 23px 10px 36px;
  border-bottom: solid 1px #dcdcdc;
  font-size: 1rem;
  color: #333;
`

const SchoolYear = styled.div`
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  text-align: center;
  /* border-radius: 5px; */
  color: #fff;
  /* margin: 20px 0 0 0; */
  padding: 20px 20px;
  background: ${Colors.blue};
  /* text-transform: uppercase; */
  font-size: 1rem;
`

const TopWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  border-bottom: solid 1px #dcdcdc;
  font-size: 1rem;
  .student {
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    margin-top: 20px;
    font-size: 1.4rem;
  }
  .grade {
    margin-top: 10px;
    text-transform: uppercase;
    font-weight: bold;
    display: inline-block;
    font-size: 0.9rem;
    background: ${Colors.green};
    color: #fff;
    border-radius: 5px;
    padding: 10px 15px;
  }
`

const SummaryLink = styled.p`
  padding: 10px;
`

const SidebarMenu: React.FC = () => {
  const portfolio = useSelectPortfolio()
  const { setSidebarOpen } = useSideMenu()
  const isDesktop = useMediaQuery('(min-width: 1200px)')

  const offset = isDesktop ? 0 : -78

  if (!portfolio) {
    return (
      <LoadingWrap>
        <Lottie options={defaultOptions} width={'100px'} height={'100px'} />
      </LoadingWrap>
    )
  }

  const { student, schoolYear } = portfolio

  const closeSidemenu = () => {
    setSidebarOpen(false)
  }

  return (
    <MenuContainer>
      <SchoolYear>
        {schoolYearRange(schoolYear.schoolYearLabel, 'School Year')}
      </SchoolYear>
      <TopWrap>
        <Avatar size={80} student={student} />
        <p className="student">{getStudentFullName(student)}</p>
        <p className="grade">{gradeLevelLabel(student.gradeLevel)}</p>
      </TopWrap>
      <CategoryWrap>
        <h1>Table of Contents</h1>
        {/* <Scroll.Link
          spy={true}
          to={'top'}
          activeClass={'active'}
          smooth={true}
          duration={500}
          offset={offset}
          onClick={closeSidemenu}
        >
          <SummaryLink>Home</SummaryLink>
        </Scroll.Link> */}
        <Scroll.Link
          data-test={'calendar'}
          spy={true}
          to={'calendar'}
          activeClass={'active'}
          smooth={true}
          duration={500}
          offset={offset}
          onClick={closeSidemenu}
        >
          <ItemLink>
            <p>Calendar</p>
          </ItemLink>
        </Scroll.Link>
        <Scroll.Link
          data-test={'school-items-count'}
          spy={true}
          to={'school-items-count'}
          activeClass={'active'}
          smooth={true}
          duration={500}
          offset={offset}
          onClick={closeSidemenu}
        >
          <ItemLink>
            <p>School Items Summary</p>
          </ItemLink>
        </Scroll.Link>
        <h1>School Items</h1>

        {portfolio.categories.map((x) => {
          const hash = categoryHashId(x.label)
          return (
            <div key={hash}>
              <Scroll.Link
                data-test={hash}
                spy={true}
                to={hash}
                activeClass={'active'}
                smooth={true}
                duration={500}
                offset={offset}
                onClick={closeSidemenu}
              >
                <CategoryItem>
                  <p className="label">{uppercaseEachWord(x.label)}</p>
                  <CountSidemenu
                    size="30px"
                    color={Colors.blue}
                    backgroundColor={Colors.white}
                    count={x.count}
                    fontSize="0.75rem"
                  ></CountSidemenu>
                </CategoryItem>
              </Scroll.Link>
              {x.items.map((item) => {
                const itemHash = itemHashId(item, x)
                return (
                  <Scroll.Link
                    data-test={itemHash}
                    spy={true}
                    to={itemHash}
                    activeClass={'active'}
                    smooth={true}
                    duration={500}
                    offset={offset}
                    key={itemHash}
                    onClick={closeSidemenu}
                  >
                    <ItemLink>
                      <p>{item.name}</p>
                    </ItemLink>
                  </Scroll.Link>
                )
              })}
            </div>
          )
        })}
      </CategoryWrap>
      <AppAdvertise />
    </MenuContainer>
  )
}

export default SidebarMenu
