export const size: Record<string, string> = {
  small: '767px',
  medium: '768px',
  large: '1200px'
}

interface BreakPoints {
  small: string
  medium: string
  large: string
}

export const device: BreakPoints = {
  small: `max-width: ${size.small}`,
  medium: `min-width: ${size.medium}`,
  large: `min-width: ${size.large}`
}

export const screenSize: Record<string, number> = {
  small: 767,
  medium: 768,
  large: 1200
}

export const MediaQuery = {
  Small: `screen and (max-width: ${size.small})`,
  Medium: `screen and (min-width: ${size.medium})`,
  Large: `screen and (min-width: ${size.large})`
}

export const MediaQuerySmall = 'small'
export const MediaQueryMedium = 'medium'
export const MediaQueryLarge = 'large'

export const mediaQuery = (key: keyof typeof screenSize) => {
  return (style: TemplateStringsArray | string): string =>
    `@media (min-width: ${screenSize[key]}px) { ${style} }`
}
