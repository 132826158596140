import React, { useEffect } from 'react'
import Lottie from 'react-lottie'
import styled from 'styled-components'
import loadingData from 'assets/lottie/loading.json'
import { useAuth0 } from '@auth0/auth0-react'
import {
  setMySchoolFilesAccessToken,
  useSelectIdentity
} from 'store/identitySlice'
import { useAppDispatch } from 'hooks/redux'
import { useParams } from 'react-router'
import { shareClient } from 'configuration'
import { setPortfolio, useSelectPortfolio } from 'store/appStateSlice'
import AppHeader from 'components/AppHeader'
import Title from 'themes/default/components/Title'
import CalendarSummaryCard from 'components/CalendarSummaryCard'
import SchoolItemSummaryCard from 'themes/default/components/SchoolItemSummaryCard'
import CategoryTableOfContents from 'themes/default/components/CategoryTableOfContents'
import CategoryItems from 'themes/default/components/CategoryItems'
import { Page, Spacing } from 'themes/default'
import SidebarMenuContainer from 'components/SidebarMenuContainer'
import SidebarMenu from 'components/SidebarMenu'
import { useSideMenu } from 'hooks/useSidemenu'

const LoadingWrap = styled.div`
  /* width: 100%; */
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

interface NavParams {
  studentId: string
  schoolYearId: string
}

const PortoflioViewer: React.FC = () => {
  const navParams = useParams<NavParams>()
  const props = useSideMenu()
  const portfolio = useSelectPortfolio()

  const dispatch = useAppDispatch()
  const {
    isLoading,
    isAuthenticated,
    buildLogoutUrl,
    user,
    getAccessTokenSilently,
    buildAuthorizeUrl
  } = useAuth0()
  const { mySchoolFilesAccessToken } = useSelectIdentity()

  useEffect(() => {
    const getToken = async () => {
      const accessToken = await getAccessTokenSilently()
      dispatch(setMySchoolFilesAccessToken(accessToken))
    }
    if (!isAuthenticated) return
    getToken()
  }, [isAuthenticated, getAccessTokenSilently, dispatch])

  useEffect(() => {
    if (isLoading) return
    if (!isAuthenticated) {
      buildAuthorizeUrl({
        appState: {
          url: window.location.href,
          studentId: navParams.studentId,
          schoolYearId: navParams.schoolYearId
        }
      }).then((auth0Url) => {
        const url = `${auth0Url}`
        window.location.href = url
      })
      return
    }
  }, [
    buildAuthorizeUrl,
    isAuthenticated,
    isLoading,
    navParams.schoolYearId,
    navParams.studentId
  ])

  useEffect(() => {
    const fetchPortfolio = async () => {
      const studentId = parseInt(navParams.studentId)
      const schoolYearId = parseInt(navParams.schoolYearId)
      try {
        const { data } = await shareClient.getPortfolioForStudentAndSchoolYear(
          studentId,
          schoolYearId
        )
        dispatch(setPortfolio(data))
      } catch (e) {
        window.location.replace('/')
      }
    }
    if (!isAuthenticated) return
    if (!mySchoolFilesAccessToken) return
    fetchPortfolio()
  }, [
    dispatch,
    isAuthenticated,
    mySchoolFilesAccessToken,
    navParams.schoolYearId,
    navParams.studentId
  ])

  if (!isAuthenticated || isLoading || !portfolio) {
    return (
      <LoadingWrap {...props}>
        <Lottie options={defaultOptions} width={'100px'} height={'100px'} />
      </LoadingWrap>
    )
  }

  // return (
  //   <div>
  //     Authorized to view it
  //     <a href={buildLogoutUrl({ returnTo: window.location.origin })}>Logout</a>
  //   </div>
  // )

  return (
    <>
      <SidebarMenuContainer>
        <SidebarMenu />
      </SidebarMenuContainer>
      <Page {...props}>
        <AppHeader />
        <Title />
        <CalendarSummaryCard />
        <Spacing>
          <SchoolItemSummaryCard />
        </Spacing>
        <CategoryTableOfContents />
        <CategoryItems />
      </Page>
    </>
  )
}

export default PortoflioViewer
