import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import PortfolioMain from 'themes/default'
import { SidemenuProvider } from 'hooks/useSidemenu'
import PasscodeScreen from 'screens/PassCodeScreen'
import ProtectedScreen from 'screens/Protected'
import NotFound404 from 'screens/NotFound404'
import PortoflioViewer from 'screens/PortfolioViewer'
import StudentAssignmentsScreen from 'screens/StudentAssignmentsScreen'
import { AssignmentContextProvider } from 'contexts/AssignmentContext'

const App: React.FC = () => {
  return (
    <SidemenuProvider>
      <Router>
        <Switch>
          <Route exact path="/assignments/student/:studentId">
            <ProtectedScreen
              mode="assignments"
              UnauthorizedComponent={<PasscodeScreen mode="assignments" />}
              AuthorizedComponent={
                <AssignmentContextProvider>
                  <StudentAssignmentsScreen />
                </AssignmentContextProvider>
              }
            />
          </Route>
          <Route
            exact
            path="/portfolio/student/:studentId/schoolYear/:schoolYearId"
          >
            <PortoflioViewer />
          </Route>
          <Route exact path="/:shareUid">
            <ProtectedScreen
              mode="portfolios"
              UnauthorizedComponent={<PasscodeScreen mode="portfolios" />}
              AuthorizedComponent={<PortfolioMain />}
            />
          </Route>
          <Route path="/">
            <NotFound404 />
          </Route>
        </Switch>
      </Router>
    </SidemenuProvider>
  )
}

export default App
