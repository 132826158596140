import { StudentDto } from '@cometloop/myschoolfiles-openapi/build/v1/myschoolfiles'
import gradeLevels from 'types/gradeLevels'

export const getStudentFullName = (student: StudentDto): string => {
  const { firstName, lastName } = student
  return `${firstName} ${lastName}`
}

export const schoolYearRange = (
  schoolYearLabel: string,
  appendString?: string
): string => {
  const year = parseInt(schoolYearLabel)
  const nextYear = year + 1
  return appendString
    ? `${year}-${nextYear} ${appendString}`
    : `${year}-${nextYear}`
}

// export const getCategoriesAsSingleLabel = (categories: Tag[]): string => {
//   if (!categories || categories.length === 0) {
//     return 'Uncategorized'
//   }

//   return categories.map((t) => t.label).join(', ')
// }

export const gradeLevelLabel = (level: string) => {
  const match = gradeLevels.find((x) => x.value === level)
  if (!match) return 'Grade not specified'
  return match.label
}

// export const genderLabel = (gender: string) => {
//   const match = genders.find((x) => x.value === gender)
//   if (!match) return 'Gender not specified'
//   return match.label
// }
