// eslint-disable-next-line @typescript-eslint/no-explicit-any
const gradeLevels: any[] = [
  {
    label: 'Kindergarten',
    value: 'k'
  },
  {
    label: '1st grade',
    value: '1'
  },
  {
    label: '2nd grade',
    value: '2'
  },
  {
    label: '3rd grade',
    value: '3'
  },
  {
    label: '4th grade',
    value: '4'
  },
  {
    label: '5th grade',
    value: '5'
  },
  {
    label: '6th grade',
    value: '6'
  },
  {
    label: '7th grade',
    value: '7'
  },
  {
    label: '8th grade',
    value: '8'
  },
  {
    label: '9th grade',
    value: '9'
  },
  {
    label: '10th grade',
    value: '10'
  },
  {
    label: '11th grade',
    value: '11'
  },
  {
    label: '12th grade',
    value: '12'
  }
]

export default gradeLevels
