import React from 'react'
import styled from 'styled-components'
import { Colors } from 'styles'

interface SubjectProps {
  name: string
}

export const Subject: React.FC<SubjectProps> = ({ name }) => {
  return (
    <Container>
      <p>{name}</p>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  background: #2196f3;
  color: ${Colors.white};
  padding: 7px 10px;
  border-radius: 20px;

  p {
    padding: 0;
    margin: 0;
  }
`
