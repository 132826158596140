import React from 'react'
import Lottie from 'react-lottie'
import styled from 'styled-components'
import loadingData from 'assets/lottie/skeleton.json'
import { useSelectPortfolio } from 'store/appStateSlice'
import { boxShadow, Colors } from 'styles'
import SummaryImage from 'themes/default/assets/Study.png'
import SectionTitle from 'themes/default/components/SectionTitle'
import AgendaItemCount from 'components/AgendaItemCount'
import Count from 'components/Count'
import ItemCard from 'components/ItemCard'
import { categoryHashId } from 'utils/links'
import { Element } from 'react-scroll'

// import Avatar from './Avatar'
// import imgStudent from 'assets/Vision.png'

const Wrap = styled.div`
  background: #fff;
`

const ItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  justify-content: flex-start;
  /* margin: 20px 40px; */
  h1 {
    background-color: #efefef;
    padding: 20px;
    text-align: left;
    /* margin: 0 40px; */
    /* padding: 20px; */
  }
`

const CategoryItem = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 23px;
  border-bottom: solid 1px #dcdcdc;
  font-size: 1.5rem;
`

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const CategoryItems: React.FC = () => {
  const portfolio = useSelectPortfolio()

  if (!portfolio) {
    return (
      <div>
        <Lottie options={defaultOptions} height={200} width={'100%'} />
      </div>
    )
  }

  const { categories } = portfolio

  return (
    <Wrap>
      {categories.map((c, i) => {
        return (
          <Element key={i} name={categoryHashId(c.label)}>
            <div>
              <SectionTitle bgColor={Colors.blue}>{c.label}</SectionTitle>
              <ItemWrap>
                {c.items.map((x) => {
                  return <ItemCard key={x.id} item={x} category={c} />
                })}
              </ItemWrap>
            </div>
          </Element>
        )
      })}
    </Wrap>
  )
}

export default CategoryItems
