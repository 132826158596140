export const Colors = {
  blue: '#2488f6',
  darkBlue: '#2a529f',
  yellow: '#f7b844',
  gray: '#75849b',
  black: '#0b182d',
  white: '#fff',
  pink: '#ff7975',
  green: '#29C6C1'
}
