import React, { useEffect } from 'react'
import styled from 'styled-components'
import CalendarSummaryCard from 'components/CalendarSummaryCard'
import Title from './components/Title'
import SchoolItemSummaryCard from './components/SchoolItemSummaryCard'
import CategoryTableOfContents from './components/CategoryTableOfContents'
import CategoryItems from './components/CategoryItems'
import { shareClient } from 'configuration'
import { useAppDispatch } from 'hooks/redux'
import { setPortfolio, useSelectPortfolio } from 'store/appStateSlice'
import {
  SidemenuContextProps,
  SIDEMENU_SIZE,
  useSideMenu
} from 'hooks/useSidemenu'
import loadingData from 'assets/lottie/loading.json'
import Lottie from 'react-lottie'
import AppHeader from 'components/AppHeader'
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { PORTFOLIOS_COOKIE_KEY } from 'utils/cookies'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

export const Spacing = styled.div`
  padding: 20px 0;
  background: #fff;
`

export const Page = styled.div<SidemenuContextProps>`
  position: relative;
  max-width: 1200px;
  box-shadow: 0 10px 100px rgba(0, 0, 0, 0.15);
  /* background: #fff; */
  /* margin: 110px auto 25px auto; */
  margin: 0 auto;
  ${(p) => (p.sidebarDocked ? `left: ${SIDEMENU_SIZE / 2}px` : null)};
`

const LoadingWrap = styled.div<SidemenuContextProps>`
  /* width: 100%; */
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  ${(p) => (p.sidebarDocked ? `left: ${SIDEMENU_SIZE / 2}px` : null)};
`

const PortfolioMain: React.FC = () => {
  const dispatch = useAppDispatch()
  const props = useSideMenu()
  const { shareUid } = useParams<any>()
  const [cookies, setCookie, removeCookie] = useCookies([PORTFOLIOS_COOKIE_KEY])

  const portfolio = useSelectPortfolio()

  useEffect(() => {
    const run = async () => {
      try {
        const { data } = await shareClient.getPortfolio(shareUid)
        dispatch(setPortfolio(data))
      } catch (e: any) {
        if (e.response.status === 404) {
          window.location.replace('/')
          removeCookie(PORTFOLIOS_COOKIE_KEY)
        }
        console.log('fatal error', e)
      }
    }

    run()
  }, [dispatch, removeCookie, shareUid])

  if (!portfolio) {
    return (
      <LoadingWrap {...props}>
        <Lottie options={defaultOptions} width={'100px'} height={'100px'} />
      </LoadingWrap>
    )
  }

  return (
    <Page {...props}>
      <AppHeader />
      <Title />
      <CalendarSummaryCard />
      <Spacing>
        <SchoolItemSummaryCard />
      </Spacing>
      <CategoryTableOfContents />
      <CategoryItems />
    </Page>
  )
}

export default PortfolioMain
