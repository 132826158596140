import {
  AssignmentDto,
  StudentDto
} from '@cometloop/myschoolfiles-openapi/build/v1/myschoolfiles'
import { assignmentClient } from 'configuration'
import { DateTime } from 'luxon'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { useCookies } from 'react-cookie'
import { useMutation, useQuery } from 'react-query'
import { ASSIGNMENTS_COOKIE_KEY } from 'utils/cookies'

export interface IPlannerContext {
  student?: StudentDto
  assignments: AssignmentDto[]
  activeSegmentItem: string
  setActiveSegmentItem: (item: string) => void
  logout: () => void
  getAssignments: (date: string) => AssignmentDto[]
  updateAssignment: (
    assignmentId: number,
    completed: boolean,
    updates: Partial<AssignmentDto>
  ) => void
}

const AssignmentContext = createContext<IPlannerContext | undefined>(undefined)

export const AssignmentContextProvider: React.FC = ({ children }) => {
  const [assignments, setAssignments] = useState<AssignmentDto[]>([])
  const [student, setStudent] = useState<StudentDto>()

  const [activeSegmentItem, setActiveSegmentItem] = useState('Today')

  const [cookies, setCookie, removeCookie] = useCookies([
    ASSIGNMENTS_COOKIE_KEY
  ])

  const { data, isLoading, isError } = useQuery('assignments', async () => {
    const result = await assignmentClient.getStudentAssignments(
      cookies[ASSIGNMENTS_COOKIE_KEY]
    )
    return {
      ...result.data,
      assignments: result.data.assignments.map((x) => {
        return {
          ...x,
          date: DateTime.fromISO(x.date).toISODate()
        }
      })
    }
  })

  const logout = () => {
    removeCookie(ASSIGNMENTS_COOKIE_KEY)
  }

  useEffect(() => {
    setStudent(data?.student)
    setAssignments(data?.assignments || [])
  }, [data])

  const { mutateAsync } = useMutation(
    async (payload: { assignmentId: number; completed: boolean }) => {
      const { data } = await assignmentClient.completeAssignment(
        payload.assignmentId,
        payload.completed,
        {
          headers: {
            AUTHORIZATION: `Bearer ${cookies[ASSIGNMENTS_COOKIE_KEY]}`
          }
        }
      )
      return data
    },
    {
      retry: 0,
      onError: (e) => {
        console.log('MUTATE error', e)
      }
    }
  )

  const getAssignments = useCallback(
    (date: string) => {
      return assignments.filter((x) => {
        return x.date === date
      })
    },
    [assignments]
  )

  const findIndexForAssignmentById = useCallback(
    (id: number): number => {
      return assignments.findIndex((x) => x.id === id)
    },
    [assignments]
  )

  const updateAssignment = useCallback(
    (
      assignmentId: number,
      completed: boolean,
      updates: Partial<AssignmentDto>
    ) => {
      let index = -1
      index = findIndexForAssignmentById(assignmentId)

      const item: AssignmentDto = {
        ...assignments[index],
        ...updates
      }
      setAssignments([
        ...assignments.slice(0, index),
        {
          ...item
        },
        ...assignments.slice(index + 1, assignments.length)
      ])
      mutateAsync({ assignmentId, completed })
    },
    [assignments, findIndexForAssignmentById, mutateAsync]
  )
  return (
    <AssignmentContext.Provider
      value={{
        activeSegmentItem,
        getAssignments,
        updateAssignment,
        logout,
        setActiveSegmentItem,
        student,
        assignments
      }}
    >
      {children}
    </AssignmentContext.Provider>
  )
}

export const useAssignmentContext = (): IPlannerContext => {
  const context = useContext(AssignmentContext)
  if (!context) {
    throw new Error('Cannot use outside of PlannerContextProvider')
  }
  return context
}
