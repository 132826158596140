import _ from 'lodash'
import { StudentPortfolioDto } from '@cometloop/myschoolfiles-openapi/build/v1/myschoolfiles'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useAppSelector } from '../hooks/redux'

interface AppState {
  ready: boolean
  didFetchUserData: boolean
  portfolio?: StudentPortfolioDto
}

export const initialState: AppState = {
  ready: false,
  didFetchUserData: false
}

export const readySlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setReady: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        ready: action.payload
      }
    },
    setDidFetchUserData: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        didFetchUserData: action.payload
      }
    },
    setPortfolio: (state, action: PayloadAction<StudentPortfolioDto>) => {
      const sorted = _.orderBy(action.payload.categories, (x) => x.label, 'asc')

      return {
        ...state,
        portfolio: {
          ...action.payload,
          categories: sorted
        }
      }
    }
  }
})

export const { setReady, setDidFetchUserData, setPortfolio } =
  readySlice.actions

export const useSelectAppState = (): AppState =>
  useAppSelector((state) => state.appState)

export const useSelectPortfolio = (): StudentPortfolioDto | undefined =>
  useAppSelector((state) => state.appState.portfolio)

export default readySlice.reducer
