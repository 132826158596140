import jwt_decode from 'jwt-decode'

export const PORTFOLIOS_COOKIE_KEY = 'portfolios'
export const ASSIGNMENTS_COOKIE_KEY = 'assignments'

export const mergeCookieValue = (
  shareUid: string,
  values: string[]
): string[] => {
  return [...values, shareUid]
}

export const isAuthorized = (shareUid: string, values: string[]): boolean => {
  const index = values.findIndex((x) => x === shareUid)
  return index >= 0 ? true : false
}

export const isStudentAssignmentsAuthorized = (
  token: string,
  studentId: number
): boolean => {
  const hasToken = token && token.length > 0 ? true : false
  if (!hasToken) {
    return false
  }
  try {
    const { studentId: tokenStudentId } = jwt_decode(token) as any
    return studentId === tokenStudentId
  } catch (e) {
    return false
  }
}
