import React from 'react'
import styled from 'styled-components'
import { boxShadow, Colors, MediaQuery } from 'styles'
import { FiMenu } from 'react-icons/fi'
import AppIcon from './AppIcon'
import { useParams } from 'react-router'
import { useSideMenu } from 'hooks/useSidemenu'
import { useAssignmentContext } from 'contexts/AssignmentContext'
import Avatar from 'components/Avatar'
import { getStudentFullName } from 'utils/helpers'
import { MdLogout } from 'react-icons/md'
import { SegmentControl } from 'components/SegmentControl'

const Nav = styled.nav`
  top: 0;
  z-index: 1;
  left: 0;
  display: flex;
  background: #fff;
  height: 78px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  ${boxShadow};
  @media ${MediaQuery.Small} {
    position: fixed;
  }
  @media ${MediaQuery.Medium} {
    position: fixed;
  }
  @media ${MediaQuery.Large} {
    position: relative;
  }
`

const ContentWrap = styled.div`
  padding: 20px;
  display: flex;
  flex: 1;
  align-items: center;
`

const Section = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`
const Center = styled(Section)`
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  flex: 1;
`

const Image = styled.img`
  width: 60px;
`

const NavLink = styled.a`
  @media ${MediaQuery.Small} {
    display: block;
  }
  @media ${MediaQuery.Medium} {
    display: block;
  }
  @media ${MediaQuery.Large} {
    display: none;
  }
`

const AssignmentHeader: React.FC = () => {
  const { activeSegmentItem, setActiveSegmentItem, student, logout } =
    useAssignmentContext()

  return (
    <Nav>
      <ContentWrap>
        {student && (
          <Section style={{ justifyContent: 'flex-start' }}>
            <Avatar size={40} student={student} />
            {/* <p style={{ padding: '0 1rem' }}>{getStudentFullName(student)}</p> */}
          </Section>
        )}

        <Center>
          <SegmentControl
            onChange={setActiveSegmentItem}
            active={activeSegmentItem}
            items={['Today', 'Week', 'Month']}
          />
        </Center>
        <Section style={{ justifyContent: 'flex-end' }}>
          <div
            onClick={logout}
            style={{
              // padding: 20,
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            {/* <p style={{ paddingRight: 10 }}>Sign out</p> */}
            <MdLogout size={30} />
          </div>
        </Section>
      </ContentWrap>
    </Nav>
  )
}

export default AssignmentHeader
