import React from 'react'
import styled from 'styled-components'
import { Colors, MediaQuery } from 'styles'
import { uppercaseEachWord } from 'utils/strings'

interface Props {
  bgColor?: string
}

const StyledSectionTitle = styled.h1<Props>`
  /* display: inline-block; */
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  text-align: center;
  /* border-radius: 5px; */
  color: #fff;
  /* margin: 20px 0 0 0; */
  padding: 20px 20px;
  background: ${(p) => (p.bgColor ? p.bgColor : Colors.blue)};
  /* text-transform: uppercase; */
  font-size: 2rem;
  @media ${MediaQuery.Small} {
    padding: 10px;
    font-size: 1.5rem;
  }
`

const SectionTitle: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <StyledSectionTitle {...rest}>
      {uppercaseEachWord(children as string)}
    </StyledSectionTitle>
  )
}

export default SectionTitle
