import {
  Category,
  Item
} from '@cometloop/myschoolfiles-openapi/build/v1/myschoolfiles'

const PORTFOLIO = 'portfolio'

export const categoryLink = (shareUid: string, categoryId: string): string => {
  return `/${PORTFOLIO}/${shareUid}/category/${categoryId}`
}

export const portfolioRoot = (shareUid: string): string => {
  return `/${PORTFOLIO}/${shareUid}`
}

export const categoryHashId = (categoryLabel: string): string => {
  return `category-${categoryLabel.replace(' ', '-')}`
}

export const itemHashId = (item: Item, category: Category): string => {
  return `item-${item.id}-category-${category.id}`
}
