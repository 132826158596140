import {
  AccountState,
  ProductPricing
} from '@cometloop/myschoolfiles-openapi/build/v1/myschoolfiles'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useAppSelector } from '../hooks/redux'

interface Account {
  customerPortalUrl: string
  productPricings: ProductPricing[]
  accountState: AccountState
}

export const initialState: Account = {
  customerPortalUrl: '',
  productPricings: [],
  accountState: {
    hasSubscription: false
  }
}

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setCustomerPortalUrl: (state, action: PayloadAction<string>): Account => {
      return {
        ...state,
        customerPortalUrl: action.payload
      }
    },
    setAccountState: (state, action: PayloadAction<AccountState>) => {
      return {
        ...state,
        accountState: action.payload
      }
    },
    setProductPricings: (
      state,
      action: PayloadAction<ProductPricing[]>
    ): Account => {
      return {
        ...state,
        productPricings: action.payload
      }
    }
  }
})

export const { setCustomerPortalUrl, setProductPricings, setAccountState } =
  accountSlice.actions

export const useSelectAccount = (): Account =>
  useAppSelector((state) => state.account)

export default accountSlice.reducer
