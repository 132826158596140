import React from 'react'
import Lottie from 'react-lottie'
import styled from 'styled-components'
import loadingData from 'assets/lottie/skeleton.json'
import { useSelectPortfolio } from 'store/appStateSlice'
import { boxShadow, Colors, MediaQuery } from 'styles'
import SummaryImage from 'themes/default/assets/Study.png'
import SectionTitle from 'themes/default/components/SectionTitle'
import AgendaItemCount from 'components/AgendaItemCount'
import { Element } from 'react-scroll'

// import Avatar from './Avatar'
// import imgStudent from 'assets/Vision.png'

const Card = styled.div`
  font-family: 'Source Sans Pro', sans-serif;
  display: flex;
  flex-direction: row;
  flex: 1;
  /* ${boxShadow}; */
  /* border-radius: 5px; */
  padding: 20px;
  background: #fff;
  @media ${MediaQuery.Small} {
    flex-direction: column-reverse;
  }
`

const AgendaItemWrap = styled.div`
  display: flex;
  /* flex: 1; */
  flex-direction: column;
  /* height: 100%; */
  justify-content: space-evenly;
  margin: 0px 40px;
  height: 600px;
  @media ${MediaQuery.Small} {
    flex: none;
    margin: 0px auto;
    height: 500px;
  }
  @media ${MediaQuery.Medium} {
    flex: none;
    /* margin: 0px 0px; */
  }
`

const ImageWrap = styled.div`
  display: flex;
  flex: 1;
  /* height: 100%; */
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* margin: 40px 0; */
`

const Image = styled.img`
  width: 90%;
`

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const SchoolItemSummaryCard: React.FC = () => {
  const portfolio = useSelectPortfolio()

  if (!portfolio) {
    return (
      <div>
        <Lottie options={defaultOptions} height={200} width={'100%'} />
      </div>
    )
  }

  const { schoolItemsCount } = portfolio

  return (
    <Element name="school-items-count">
      <div>
        <SectionTitle>School Items</SectionTitle>
        <Card>
          <AgendaItemWrap>
            <AgendaItemCount
              color={Colors.blue}
              label="School Items"
              count={schoolItemsCount.itemCount}
            />
            <AgendaItemCount
              color={Colors.yellow}
              label="Categories"
              count={schoolItemsCount.categoryCount}
            />
            <AgendaItemCount
              color={Colors.pink}
              label="Documents"
              count={schoolItemsCount.documentCount}
            />
            <AgendaItemCount
              color={Colors.green}
              label="Links"
              count={schoolItemsCount.urlCount}
            />
          </AgendaItemWrap>
          <ImageWrap>
            <Image src={SummaryImage} />
          </ImageWrap>
        </Card>
      </div>
    </Element>
  )
}

export default SchoolItemSummaryCard
