import React from 'react'
import styled from 'styled-components'
import { MediaQuery } from 'styles'

const Image = styled.img`
  width: 30px;
  height: auto;
  @media ${MediaQuery.Medium} {
    width: 40px;
  }
`

const AppIcon: React.FC = () => {
  return (
    <a href="https://myschoolfiles.com">
      <Image src="/app-icon.png" alt="My School Files" />
    </a>
  )
}

export default AppIcon
