import React from 'react'
import styled from 'styled-components'
import loadingData from 'assets/lottie/skeleton.json'
import { boxShadow, Colors, MediaQuery } from 'styles'
import imgStudent from 'assets/Vision.png'
import {
  Document,
  Photo,
  RequirementPhoto
} from '@cometloop/myschoolfiles-openapi/build/v1/myschoolfiles'
import { useInView } from 'react-intersection-observer'

const Card = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const DocWrap = styled.div`
  padding: 5px 10px;
  /* display: block; */

  /* align-items: center; */
`
const HeaderTitle = styled.h2`
  font-size: 1.3rem;
  background-color: #efefef;
  padding: 20px;
  @media ${MediaQuery.Small} {
    font-size: 1rem;
    padding: 10px;
    /* margin: 10px 0; */
  }
`

interface DocImageProps {
  src: string
}

const DocImage = styled.div<DocImageProps>`
  background: ${(p) => `url('${p.src}') no-repeat center #efefef`};
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-size: cover;
  @media ${MediaQuery.Small} {
    width: 50px;
    height: 50px;
  }
`

interface Props {
  docs: RequirementPhoto[]
}

const DocumentsCard: React.FC<Props> = ({ docs }) => {
  const { inView, ref } = useInView({
    triggerOnce: true
  })

  if (docs.length === 0) return null
  // if (docs.length === 0) return <p>No documents</p>

  return (
    <div ref={ref}>
      <HeaderTitle>Documents</HeaderTitle>
      <Card>
        {docs.length > 0 &&
          docs.map((x, i) => {
            const url = inView ? x.url : ''
            return (
              <a key={i} href={x.url} target="_blank" rel="noreferrer">
                <DocWrap>
                  <DocImage src={url} />
                </DocWrap>
              </a>
            )
          })}
      </Card>
    </div>
  )
}

export default DocumentsCard
