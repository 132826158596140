import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams
} from 'react-router-dom'
import styled from 'styled-components'
import SidebarMenu from 'components/SidebarMenu'
import PortfolioMain from 'themes/default'
import SidebarMenuContainer from 'components/SidebarMenuContainer'
import { SidemenuProvider } from 'hooks/useSidemenu'
import { useCookies } from 'react-cookie'
import PasscodeScreen from 'screens/PassCodeScreen'
import {
  PORTFOLIOS_COOKIE_KEY,
  isAuthorized,
  ASSIGNMENTS_COOKIE_KEY,
  isStudentAssignmentsAuthorized
} from 'utils/cookies'
import { RunMode } from 'types/runMode'

interface Props {
  mode?: RunMode
  AuthorizedComponent: React.ReactNode
  UnauthorizedComponent: React.ReactNode
}

const ProtectedScreen: React.FC<Props> = ({
  mode = 'portfolios',
  AuthorizedComponent,
  UnauthorizedComponent
}) => {
  const [cookies, setCookie] = useCookies([
    PORTFOLIOS_COOKIE_KEY,
    ASSIGNMENTS_COOKIE_KEY
  ])
  const { shareUid, studentId } = useParams<any>()

  const hasAccess = (): boolean => {
    if (mode === 'assignments') {
      return isStudentAssignmentsAuthorized(
        cookies[ASSIGNMENTS_COOKIE_KEY],
        studentId
      )
    } else {
      return isAuthorized(shareUid, cookies[PORTFOLIOS_COOKIE_KEY] || [])
    }
  }

  const authorized = hasAccess()

  if (!authorized) {
    return <>{UnauthorizedComponent}</>
  }

  if (mode === 'assignments') {
    return <>{AuthorizedComponent}</>
  } else {
    return (
      <>
        <SidebarMenuContainer>
          <SidebarMenu />
        </SidebarMenuContainer>
        {AuthorizedComponent}
      </>
    )
  }
}

export default ProtectedScreen
