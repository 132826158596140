import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import Lottie from 'react-lottie'
import styled from 'styled-components'
import { ASSIGNMENTS_COOKIE_KEY } from 'utils/cookies'
import loadingData from 'assets/lottie/loading.json'
import { Wrap } from 'themes/default/components/Title'
import { DateTime, Interval } from 'luxon'
import { AssignmentDay } from 'components/AssignmentDay'
import { useAssignmentContext } from 'contexts/AssignmentContext'
import AssignmentHeader from 'components/AssignmentHeader'
import { Colors } from 'styles'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const getWeekStartAndEnd = (date: DateTime) => {
  const weekStart =
    date.weekday !== 7 ? date.minus({ days: date.weekday }) : date
  const weekEnd = weekStart.plus({ days: 6 })
  return {
    weekStart,
    weekEnd
  }
}

const StudentAssignmentsScreen: React.FC = () => {
  const [cookies, setCookie, removeCookie] = useCookies([
    ASSIGNMENTS_COOKIE_KEY
  ])

  const [days, setDays] = useState<DateTime[]>([])

  const { student, getAssignments, activeSegmentItem } = useAssignmentContext()

  useEffect(() => {
    const date = DateTime.now()
    const min = DateTime.now().startOf('month')
    const max = DateTime.now().endOf('month')

    const i = Interval.fromDateTimes(min, max).splitBy({
      day: 1
    })
    const _days: DateTime[] = []

    i.forEach((x) => {
      _days.push(x.start)
    })

    if (activeSegmentItem === 'Today') {
      setDays(_days.filter((x) => x.toISODate() === DateTime.now().toISODate()))
      return
    }

    if (activeSegmentItem === 'Week') {
      const { weekStart, weekEnd } = getWeekStartAndEnd(DateTime.now())
      setDays(
        _days.filter((x) => x.day >= weekStart.day && x.day <= weekEnd.day)
      )
      return
    }

    setDays(_days)
  }, [activeSegmentItem])

  const getTodayLabel = () => {
    if (activeSegmentItem === 'Today') {
      return DateTime.now().toFormat('DDDD')
    } else if (activeSegmentItem === 'Week') {
      const { weekStart, weekEnd } = getWeekStartAndEnd(DateTime.now())
      return `${weekStart.toFormat('MMMM dd, y')} - ${weekEnd.toFormat(
        'MMMM dd, y'
      )}`
    } else {
      return DateTime.now().toFormat('MMMM y')
    }
  }

  if (!student) {
    return (
      <LoadingWrap>
        <Lottie options={defaultOptions} width={'100px'} height={'100px'} />
      </LoadingWrap>
    )
  }

  return (
    <Wrap>
      <AssignmentHeader />

      <TodayLabel>{getTodayLabel()}</TodayLabel>

      {days.map((x) => {
        const daysAssignments = getAssignments(x.toISODate())

        return (
          <AssignmentDay
            student={student}
            assignments={daysAssignments}
            key={x.toISO()}
            day={x}
          />
        )
      })}
    </Wrap>
  )
}

const TodayLabel = styled.p`
  font-size: 1.2rem;
  padding: 1rem;
  color: #fff;
  background-color: ${Colors.darkBlue};
  text-align: center;
  font-weight: 700;
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
`

const LoadingWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

export default StudentAssignmentsScreen
