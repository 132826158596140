import { createGlobalStyle } from 'styled-components'
import { Colors } from './colors'
import { blueGradientMixin } from './mixins'
import { cssReset } from './reset'

interface Props {
  background?: string
}

export const GlobalStyle = createGlobalStyle<Props>`

  ${cssReset}  

  body {

    /* background: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRunq49zF9GVdcm-jGqlGukIDlTX9cWd6WnSQ&usqp=CAU') */
    background-color:#efefef;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.0rem;
    /* ${blueGradientMixin} */
  }
  .strikethrough {
  position: relative;
}
  .strikethrough:before {
    position: absolute;
    width: 100%;
    content: "";
    left: 15%;
    top: 50%;
    right: 0;
    border-top: 8px solid;
    border-color: red;
/* 
    -webkit-transform:rotate(-5deg);
    -moz-transform:rotate(-5deg);
    -ms-transform:rotate(-5deg);
    -o-transform:rotate(-5deg); */
    transform:rotate(-50deg);
  }

  a {
    cursor: pointer;
    text-decoration:none;
    color: ${Colors.blue}
  }
  p {
    line-height: 125%;
  }

`
