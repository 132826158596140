import {
  AssignmentDto,
  Student,
  StudentDto
} from '@cometloop/myschoolfiles-openapi/build/v1/myschoolfiles'
import { Subject } from 'components/Subject'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { BsFillCircleFill, BsCheckCircleFill } from 'react-icons/bs'
import { Colors } from 'styles'
import { useAssignmentContext } from 'contexts/AssignmentContext'
export interface AssignmentItemProps {
  assignment: AssignmentDto
  student: StudentDto
  onComplete?: (assignment: AssignmentDto) => void
}

const checkBoxSize = 30

export const AssignmentItem: React.FC<AssignmentItemProps> = ({
  student,
  assignment,
  onComplete
}) => {
  const isChecked = assignment.studentsCompleted.find((x) => x === student.id)

  const { updateAssignment } = useAssignmentContext()

  const getIcon = () => {
    if (isChecked) {
      return <BsCheckCircleFill size={checkBoxSize} color={Colors.green} />
    } else {
      return <BsFillCircleFill size={checkBoxSize} color={'#dcdcdc'} />
    }
  }

  const onToggleComplete = useCallback(() => {
    //

    const index = assignment.studentsCompleted.findIndex(
      (x) => x === student?.id
    )

    const didComplete = index === -1 ? true : false

    const completed = assignment.studentsCompleted.filter(
      (x) => x !== student?.id
    )

    if (index === -1) {
      completed.push(student?.id)
    }

    updateAssignment(assignment.id as number, didComplete, {
      studentsCompleted: [...completed]
    })
  }, [assignment, student, updateAssignment])

  const { category, date, students, studentsCompleted, title, id } = assignment
  return (
    <Item>
      <CheckArea onClick={onToggleComplete}>{getIcon()}</CheckArea>
      <Column>
        <Subject name={category} />
        <AssignmentTitle>{title}</AssignmentTitle>
      </Column>
    </Item>
  )
}

const Item = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  /* padding: 2rem 1rem; */
  border-bottom: solid 1px #dcdcdc;
`

const CheckArea = styled.div`
  /* width: 100px; */
  padding: 2rem 1.5rem;
  cursor: pointer;
`

const AssignmentTitle = styled.h1`
  padding: 7px 10px;
`

const Column = styled.div`
  display: flex;
  font-size: 1rem;
  flex: 1;
  flex-direction: column;
  padding: 0 1.5rem 0 0;
`
