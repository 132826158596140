import React from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import { Provider } from 'react-redux'
import store from './store'
import { CookiesProvider } from 'react-cookie'
import App from 'App'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { GlobalStyle } from 'styles'

const queryClient = new QueryClient()

const AppWrapper: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRedirectCallback = (appState: any) => {
    const { url } = appState
    window.location.replace(url)
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
          redirectUri={window.location.origin}
          audience={process.env.REACT_APP_AUTH0_AUDIENCE}
          scope={process.env.REACT_APP_AUTH0_SCOPES}
          useRefreshTokens={true}
          onRedirectCallback={onRedirectCallback}
        >
          <CookiesProvider>
            <React.Fragment>
              <GlobalStyle />
              <App />
            </React.Fragment>
          </CookiesProvider>
        </Auth0Provider>
      </Provider>
    </QueryClientProvider>
  )
}

export default AppWrapper
