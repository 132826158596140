import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Column } from 'styles'
import loadingData from 'assets/lottie/loading.json'
import Lottie from 'react-lottie'

const Container = styled.div`
  margin: 100px 0;
  text-align: center;
`

const Center = styled(Column)`
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 100vh;
`

const Logo = styled.img`
  height: 100px;
`

const ActionTitle = styled.p`
  /* color: red; */
  font-size: 1.6rem;
  font-weight: 700;
`

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const LoadingWrap = styled.div`
  /* width: 100%; */
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

const NotFound404: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false)
    }, 3000)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  if (isLoading) {
    return (
      <LoadingWrap>
        <Lottie options={defaultOptions} width={'100px'} height={'100px'} />
      </LoadingWrap>
    )
  }

  return (
    <Center>
      <Logo src="/app-icon.png" alt="My School Files" />
      <Container>
        <ActionTitle>404 Not Found</ActionTitle>
      </Container>
    </Center>
  )
}

export default NotFound404
