import React from 'react'
import styled from 'styled-components'
import { boxShadow, Colors, MediaQuery } from 'styles'
import { FiMenu } from 'react-icons/fi'
import AppIcon from './AppIcon'
import { useParams } from 'react-router'
import { useSideMenu } from 'hooks/useSidemenu'

const Nav = styled.nav`
  /* position: fixed; */
  top: 0;
  z-index: 1;
  left: 0;
  display: flex;
  background: #fff;
  height: 78px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  ${boxShadow};
  @media ${MediaQuery.Small} {
    position: fixed;
  }
  @media ${MediaQuery.Medium} {
    position: fixed;
  }
  @media ${MediaQuery.Large} {
    position: relative;
  }
`

const ContentWrap = styled.div`
  padding: 20px;
  display: flex;
  flex: 1;
  align-items: center;
`

const Section = styled.div`
  display: flex;
  flex: 1;
`
const Center = styled(Section)`
  justify-content: center;
  align-items: flex-start;
`

const Image = styled.img`
  width: 60px;
`

const NavLink = styled.a`
  @media ${MediaQuery.Small} {
    display: block;
  }
  @media ${MediaQuery.Medium} {
    display: block;
  }
  @media ${MediaQuery.Large} {
    display: none;
  }
`

const AppHeader: React.FC = () => {
  const { shareUid } = useParams<any>()

  const { sidebarOpen, setSidebarOpen } = useSideMenu()

  return (
    <Nav>
      <ContentWrap>
        <Section>
          <NavLink
            style={{ cursor: 'pointer' }}
            onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            <FiMenu color={Colors.black} size={30} />
          </NavLink>
        </Section>
        <Center>
          <AppIcon />
        </Center>
        <Section></Section>
      </ContentWrap>
    </Nav>
  )
}

export default AppHeader
