import accountSlice from './accountSlice'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import identitySlice from './identitySlice'
import appStateSlice from './appStateSlice'

const combinedReducer = combineReducers({
  identity: identitySlice,
  account: accountSlice,
  appState: appStateSlice
})

const rootReducer = (state: any, action: any) => {
  if (action.type === 'RESET') {
    state = undefined
  }
  return combinedReducer(state, action)
}

const store = configureStore({
  reducer: rootReducer
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
