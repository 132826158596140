import React from 'react'
import Lottie from 'react-lottie'
import styled from 'styled-components'
import loadingData from 'assets/lottie/skeleton.json'
import { useSelectPortfolio } from 'store/appStateSlice'
import {
  boxShadow,
  Colors,
  Column,
  MediaQuery,
  mediaQuery,
  MediaQuerySmall,
  Row
} from 'styles'
import {
  getStudentFullName,
  gradeLevelLabel,
  schoolYearRange
} from 'utils/helpers'
import imgStudent from 'assets/Vision.png'
import Avatar from 'components/Avatar'
import { ReactComponent as MainImage } from 'themes/default/assets/Portfolio.svg'
import SectionTitle from './SectionTitle'
import { Element } from 'react-scroll'
import { useSideMenu } from 'hooks/useSidemenu'
import bgImage from 'assets/bg.jpg'
import TitleAvatar from 'components/TitleAvatar'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

// const topimage = 'https://cdn.statically.io/img/img4.goodfon.com/wallpaper/nbig/a/8b/vector-blue-squares-abstract-background-geometric.jpg'

export const Wrap = styled.div`
  background-color: ${Colors.white};
  /* padding-top: 79px; */
  position: relative;
  top: 78px;

  @media ${MediaQuery.Medium} {
    /* top: 78px; */
    /* padding-top: 90px; */
  }
  @media ${MediaQuery.Large} {
    /* padding-top: 0; */
    top: 0;
  }
`

const Card = styled.div`
  /* border-radius: 5px; */
  /* height: 500px; */
  padding: 70px 0px 0 0;

  @media ${MediaQuery.Medium} {
    padding: 100px 0px;
  }

  /* background: ${() => `url('${imgStudent}') no-repeat top right #fff`}; */
  /* background: url('https://i.pinimg.com/originals/43/a1/fc/43a1fcc31f8e39e5f90351ded56aa4be.jpg')
    no-repeat center; */
  background: ${() => `url('${bgImage}')`};
  background-size: cover;
  /* background-size:  */

  /* background-size: 480px; */
`

const StyledRow = styled(Row)`
  align-items: center;
  justify-content: center;
`

const StyledCol = styled(Column)`
  /* height: 100vh; */
  height: 100%;
  /* margin-top: 150px; */
  align-items: center;
  justify-content: center;
  /* margin-bottom: 150px; */
`

const NameWrap = styled.div`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  /* padding: 40px 0 140px 0; */
  font-size: 2.5rem;
  /* color: #fff; */
  text-align: center;
  margin: 20px 0 0 0;
  /* padding-bottom: 50px; */

  @media ${MediaQuery.Small} {
    font-size: 2.25rem;
    padding-bottom: 40px;
  }
  .schoolYear {
    margin: 10px 0 0 0;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    display: inline-block;
    font-size: 1.2rem;
    background: ${Colors.green};
    color: #fff;
    border-radius: 5px;
    padding: 15px 20px;
    @media ${MediaQuery.Small} {
      padding: 10px 15px;
      font-size: 0.9rem;
    }
  }
`

const Title: React.FC = () => {
  const portfolio = useSelectPortfolio()

  if (!portfolio) {
    return (
      <div>
        <Lottie options={defaultOptions} height={200} width={'100%'} />
      </div>
    )
  }

  const { student, schoolYear } = portfolio

  return (
    <Element name="top">
      <Wrap>
        <SectionTitle>
          {schoolYearRange(schoolYear.schoolYearLabel, 'School Year')}
        </SectionTitle>
        <Card>
          <StyledCol>
            <TitleAvatar size={200} student={student} />
            <NameWrap>
              <p>{getStudentFullName(student)}</p>
              <p className="schoolYear">
                {gradeLevelLabel(student.gradeLevel)}
              </p>
              {/* <p className="schoolYear">
                {schoolYearRange(schoolYear.schoolYearLabel)}
              </p> */}
            </NameWrap>
          </StyledCol>
        </Card>
      </Wrap>
    </Element>
  )
}

export default Title
