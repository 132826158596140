import React from 'react'
import Lottie from 'react-lottie'
import styled from 'styled-components'
import loadingData from 'assets/lottie/skeleton.json'
import { useSelectPortfolio } from 'store/appStateSlice'
import { boxShadow, Colors, MediaQuery } from 'styles'
import SummaryImage from 'themes/default/assets/Study.png'
import SectionTitle from 'themes/default/components/SectionTitle'
import AgendaItemCount from 'components/AgendaItemCount'
import Count from 'components/Count'
import Scroll from 'react-scroll'
import { categoryHashId } from 'utils/links'
import CountTableOfContents from 'components/CountTableOfContents'
import { uppercaseEachWord } from 'utils/strings'
import useMediaQuery from 'hooks/useMediaQuery'

// import Avatar from './Avatar'
// import imgStudent from 'assets/Vision.png'

const Wrap = styled.div`
  background: #fff;
`

const CategoryWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  justify-content: flex-start;
  /* margin: 20px 40px; */
  h1 {
    background-color: #efefef;
    padding: 20px;
    text-align: left;
    /* margin: 0 40px; */
    /* padding: 20px; */
  }
`

const CategoryItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 60px;
  border-bottom: solid 1px #dcdcdc;
  font-size: 1.5rem;
  @media ${MediaQuery.Small} {
    font-size: 1.2rem;
    padding: 20px 15px;
  }
`

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const CategoryList: React.FC = () => {
  const portfolio = useSelectPortfolio()
  const isDesktop = useMediaQuery('(min-width: 1200px)')

  const offset = isDesktop ? 0 : -78

  if (!portfolio) {
    return (
      <div>
        <Lottie options={defaultOptions} height={200} width={'100%'} />
      </div>
    )
  }

  return (
    <Wrap>
      <SectionTitle>Categories</SectionTitle>
      <CategoryWrap>
        {portfolio.categories.map((x, i) => {
          return (
            <Scroll.Link
              key={i}
              data-test={categoryHashId(x.label)}
              spy={true}
              to={categoryHashId(x.label)}
              activeClass={'active'}
              smooth={true}
              duration={500}
              offset={offset}
            >
              <CategoryItem>
                <p>{uppercaseEachWord(x.label)}</p>
                <CountTableOfContents
                  size="40px"
                  backgroundColor={Colors.blue}
                  count={x.count}
                  fontSize="1.0rem"
                ></CountTableOfContents>
              </CategoryItem>
            </Scroll.Link>
          )
        })}
      </CategoryWrap>
    </Wrap>
  )
}

export default CategoryList
