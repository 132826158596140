import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useAppSelector } from '../hooks/redux'

export interface Identity {
  accessToken: string
  mySchoolFilesAccessToken: string
  email: string
  user?: any
}

export const EmptyIdentity: Identity = {
  accessToken: '',
  mySchoolFilesAccessToken: '',
  email: ''
}

const initialState: Identity = EmptyIdentity

export const identitySlice = createSlice({
  name: 'identity',
  initialState,
  reducers: {
    resetIdentity: () => {
      return {
        ...EmptyIdentity
      }
    },
    setIdentity: (state, action: PayloadAction<Identity>): Identity => {
      return {
        ...state,
        ...action.payload
      }
    },
    setUser: (state, action: PayloadAction<any | undefined>): Identity => {
      return {
        ...state,
        user: action.payload
      }
    },
    setMySchoolFilesAccessToken: (
      state,
      action: PayloadAction<string>
    ): Identity => {
      return {
        ...state,
        mySchoolFilesAccessToken: action.payload
      }
    }
  }
})

export const {
  setIdentity,
  resetIdentity,
  setUser,
  setMySchoolFilesAccessToken
} = identitySlice.actions

export const useSelectIdentity = (): Identity =>
  useAppSelector((state) => state.identity)

export default identitySlice.reducer
