import { css } from 'styled-components'

export const blueGradientMixin = css`
  background: radial-gradient(
    circle at left top,
    rgba(12, 180, 206, 0.9) 9%,
    rgba(0, 108, 255, 0.9) 48%,
    rgba(100, 66, 255, 0.9) 91%
  );
`
