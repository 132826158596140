import React from 'react'
import styled from 'styled-components'
import { MediaQuery } from 'styles'
import { Url } from '@cometloop/myschoolfiles-openapi/build/v1/myschoolfiles'

const Card = styled.div`
  padding: 20px 0;
  @media ${MediaQuery.Small} {
    padding: 10px 0;
  }
`

const HeaderTitle = styled.h2`
  font-size: 1.3rem;
  background-color: #efefef;
  padding: 20px;
  @media ${MediaQuery.Small} {
    font-size: 1rem;
    padding: 10px;
    /* margin: 10px 0; */
  }
`

const LinkWrap = styled.a`
  padding: 5px 0;
  word-wrap: break-word;
  display: block;
  font-size: 1.5rem;
  /* display: flex; */
  /* flex-direction: row; */
  /* align-items: center; */
  @media ${MediaQuery.Small} {
    font-size: 1rem;
    /* margin: 10px 0; */
  }
`

interface Props {
  urls: Url[]
}

const LinksCard: React.FC<Props> = ({ urls }) => {
  if (urls.length === 0) return null
  return (
    <div>
      <HeaderTitle>Links</HeaderTitle>
      <Card>
        {urls.length > 0 &&
          urls.map((x, i) => (
            <LinkWrap key={i} href={x.url} target="_blank">
              {x.url}
            </LinkWrap>
          ))}
      </Card>
    </div>
  )
}

export default LinksCard
