import { useEffect, useState } from 'react'

const useMediaQuery = (query: string): boolean => {
  const mediaQuery = window.matchMedia(query)
  const [match, setMatch] = useState(!!mediaQuery.matches)

  useEffect(() => {
    const onChange = () => {
      setMatch(!!mediaQuery.matches)
    }
    window.addEventListener('load', onChange)
    window.addEventListener('resize', onChange)
    return () => {
      window.removeEventListener('load', onChange)
      window.removeEventListener('resize', onChange)
    }
  }, [mediaQuery])

  if (typeof window === 'undefined' || typeof window.matchMedia === 'undefined')
    return false

  return match ? true : false
}

export default useMediaQuery
