import {
  SidemenuContextProps,
  SIDEMENU_SIZE,
  useSideMenu
} from 'hooks/useSidemenu'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div<SidemenuContextProps>`
  display: ${(p) => (p.sidebarDocked || p.sidebarOpen ? 'block' : 'none')};
  width: ${SIDEMENU_SIZE}px;
  position: fixed;
  z-index: 3;
  height: 100vh;
  background-color: #fff;
  box-shadow: 10px 0px 50px rgba(0, 0, 0, 0.2);
`

const Overlay = styled.div<SidemenuContextProps>`
  display: ${(p) => (p.sidebarOpen ? 'block' : 'none')};
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
`

const SidebarMenuContainer: React.FC = ({ children }) => {
  const props = useSideMenu()
  const { setSidebarOpen } = props
  return (
    <>
      <Container id="menu" {...props}>
        {children}
      </Container>
      <Overlay onClick={() => setSidebarOpen(false)} {...props} />
    </>
  )
}

export default SidebarMenuContainer
