import React from 'react'
import Lottie from 'react-lottie'
import styled from 'styled-components'
import loadingData from 'assets/lottie/skeleton.json'
import { useSelectPortfolio } from 'store/appStateSlice'
import { boxShadow, Colors, MediaQuery } from 'styles'

// import Avatar from './Avatar'
// import imgStudent from 'assets/Vision.png'

interface Props {
  count: number
  backgroundColor: string
  size?: string
  fontSize?: string
}

const CountTableOfContents: React.FC<Props> = (props) => {
  const { count } = props

  return (
    <StyledCount {...props}>
      <p>{count}</p>
    </StyledCount>
  )
}

const circleSize = 60
const smallCircleSize = 40

const StyledCount = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${smallCircleSize}px;
  height: ${smallCircleSize}px;

  @media ${MediaQuery.Medium} {
    width: ${circleSize}px;
    height: ${circleSize}px;
  }

  ${boxShadow};
  border-radius: 50%;
  background: ${(p) => p.backgroundColor};
  p {
    color: #fff;
    font-size: 1rem;

    @media ${MediaQuery.Medium} {
      font-size: 1.5rem;
    }
  }
`

export default CountTableOfContents
