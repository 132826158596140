import { StudentDto } from '@cometloop/myschoolfiles-openapi/build/v1/myschoolfiles'
import React from 'react'
import styled from 'styled-components'
import { Colors, MediaQuery } from 'styles'

interface Props {
  student: StudentDto
  size?: number
}

const Circle = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 20px 80px rgba(0, 0, 0, 0.5);
  border: solid 3px #fff;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: ${Colors.blue};
  background-image: ${(p) =>
    p.student.avatarUrl ? `url('${p.student.avatarUrl}')` : 'none'};
  background-position: center;
  background-size: cover;
  @media ${MediaQuery.Small} {
    border: solid 3px #fff;
    width: 100px;
    height: 100px;
  }
  p {
    font-size: 2.5rem;
    @media ${MediaQuery.Medium} {
      font-size: 5rem;
    }

    color: #fff;
  }
  img {
    width: 100%;
    border-radius: 50%;
    /* height: 100%; */
  }
`

const TitleAvatar: React.FC<Props> = (props) => {
  const { avatarUrl, firstName, lastName } = props.student

  if (!avatarUrl) {
    const firstInitial = firstName.charAt(0).toUpperCase()
    const lastInitial = lastName.charAt(0).toUpperCase()

    const name = `${firstInitial}${lastInitial}`
    return (
      <Circle {...props}>
        <p>{name}</p>
      </Circle>
    )
  }

  return <Circle {...props}></Circle>
}

export default TitleAvatar
