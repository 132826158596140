import React from 'react'
import Count from './Count'
import styled from 'styled-components'
import { MediaQuery } from 'styles'

interface Props {
  count: number
  color: string
  label: string
}

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  /* flex: 1; */

  font-family: Arial, Helvetica, sans-serif;
  /* justify-content: center; */
  align-items: center;
  .label {
    /* margin-top: 20px; */
    margin: 0 30px;
    font-size: 1.3rem;
    font-weight: bold;
    text-transform: uppercase;
    @media ${MediaQuery.Medium} {
      font-size: 1.6rem;
    }
  }
`

const AgendaItemCount: React.FC<Props> = ({ count, color, label }) => {
  return (
    <Wrap>
      <Count
        fontSize={'40px'}
        size={'80px'}
        backgroundColor={color}
        count={count}
      />
      <p className="label">{label}</p>
    </Wrap>
  )
}

export default AgendaItemCount
