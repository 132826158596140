import React, { useContext, useEffect, useState } from 'react'

export interface SidemenuContextProps {
  sidebarOpen: boolean
  sidebarDocked: boolean
  setSidebarOpen: (open: boolean) => void
  setSidebarDocked: (docked: boolean) => void
}

const SidemenuContext = React.createContext<SidemenuContextProps | undefined>(
  undefined
)
const dockSize = 1200
export const SIDEMENU_SIZE = 320

const mql = window.matchMedia(`(min-width: ${dockSize}px)`)

export const SidemenuProvider: React.FC = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [sidebarDocked, setSidebarDocked] = useState(mql.matches)

  const onChange = () => {
    setSidebarDocked(mql.matches)
    setSidebarOpen(false)
  }

  useEffect(() => {
    mql.addEventListener('change', onChange)
    return () => {
      mql.removeEventListener('change', onChange)
    }
  }, [])

  return (
    <SidemenuContext.Provider
      value={{
        sidebarDocked,
        setSidebarDocked,
        sidebarOpen,
        setSidebarOpen
      }}
    >
      {children}
    </SidemenuContext.Provider>
  )
}

export const useSideMenu = () => {
  const context = useContext(SidemenuContext)
  if (context === undefined) {
    throw new Error('Must be inside useSideMenu provider')
  }
  return context
}
