import {
  AssignmentDto,
  StudentDto
} from '@cometloop/myschoolfiles-openapi/build/v1/myschoolfiles'
import { AssignmentItem } from 'components/AssignmentItem'
import Count from 'components/Count'
import DayCircle from 'components/DayCircle'
import { useAssignmentContext } from 'contexts/AssignmentContext'
import { DateTime } from 'luxon'
import React from 'react'
import styled from 'styled-components'
import { Colors } from 'styles'

export interface AssignmentDayProps {
  day: DateTime
  student: StudentDto
  assignments: AssignmentDto[]
}

export const AssignmentDay: React.FC<AssignmentDayProps> = ({
  day,
  student,
  assignments
}) => {
  const dateLabel = day.toFormat('ccc')

  return (
    <Wrap hasAny={assignments.length > 0}>
      <DateBox>
        <p className="date-label">{dateLabel}</p>
        <DayCircle count={day.day} />
        {day.hasSame(DateTime.now(), 'day') && (
          <p
            style={{
              fontWeight: 'bold',
              fontSize: '0.7rem',
              paddingTop: '0.5rem',
              color: '#f44336'
            }}
          >
            TODAY
          </p>
        )}
      </DateBox>
      <AssignmentBox>
        {assignments.length === 0 && (
          <NoAssignmentsBox>
            <p>No assignments planned.</p>
          </NoAssignmentsBox>
        )}
        {assignments.map((x) => (
          <AssignmentItem student={student} key={x.id} assignment={x} />
        ))}
      </AssignmentBox>
    </Wrap>
  )
}

const Wrap = styled.div<{ hasAny: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: row;
  border-bottom: ${(p) => (p.hasAny ? 'none' : 'solid 1px #dcdcdc;')};
`

const DateBox = styled.div`
  width: 4rem;
  padding: 1rem;
  background: #efefef;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-bottom: solid 1px #dcdcdc;

  p.date-label {
    font-size: 1.1rem;
    color: #999;
    padding: 0.25rem 0;
  }
`

const AssignmentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`

const NoAssignmentsBox = styled.div`
  padding: 1rem;
  p {
    font-style: italic;
    font-size: 0.9rem;
    color: #999;
  }
`
